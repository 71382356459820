import SelectSimple from 'Components/public/SelectSimple.js';

class SelectValidationEmailTemplate extends SelectSimple {

  static get properties() {
    return {
      search: { type: Boolean },
      class: { type: String },
    }
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            no_item:'No template available, please add some.',
          },
        },
        french:{
          translation: {
            no_item:'Aucune template disponible, veuillez en ajouter.',
          }
        }
      }
    ]
  }

  displayFields(item) {
    return item[this.itemTitleField];
  }

  constructor() {
    super();
    this.apiEndpoint = 'private/admin/system/templates/email';
    this.itemTitleField = 'name';
    this.inherit = true;
  }

}

customElements.define('select-validation-email-template', SelectValidationEmailTemplate);