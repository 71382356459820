import { BaseElement } from 'Components';
import App from 'App';
import { Loaders } from 'Utils';

function deepCloneWithFunctions(obj) {
  if (typeof obj !== 'object' || obj === null) return obj;
  if (typeof obj === 'function') return obj;

  const newObj = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      newObj[key] = deepCloneWithFunctions(obj[key]);
    }
  }

  return newObj;
}

class ApexChart extends BaseElement {

  static get properties() {
    return {
      spinnerDelay:           { type: Number },
      height:                 { type: Number },
      width:                  { type: Number },
      type:                   { type: String },
      stacked:                { type: Boolean },
      xAxisType:              { type: String },
      xAxisRange:             { type: Array },
      yAxisMax:               { type: Number },
      labelHide:              { type: Boolean },
      labelPosition:          { type: String },
      labelWidth:             { type: Number },
      labelFontSize:          { type: String },
      dataLabelsEnabled:      { type: Boolean },
      dataLabelsFontSize:     { type: String },
      legendFontSize:         { type: String },
      legendPosition:         { type: String },
      legendHide:             { type: Boolean },
      animationsEnabled:      { type: Boolean },
      strokeCurve:            { type: String },
      strokeWidth:            { type: Number },
      strokeFillType:         { type: String },
      strokeColors:           { type: Array },
      fillType:               { type: String },
      fillGradientShade:      { type: String },
      fillGradientType:       { type: String },
      gradientShade:          { type: String },
      gradientType:           { type: String },
      gradientInverseColors:  { type: Boolean },
      pieCustomScale:         { type: Number },

      series:             { type: Array },
      labels:             { type: Array },
      colors:             { type: Array },

      debug:              { type: Boolean },
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.firstTime = true;
    this.apexLoaded = false;
    this.seriesFormatByType = {
      line:'timeseries',
      area:'timeseries',
      bar:'timeseries',
      scatter:'timeseries',
      heatmap:'timeseries',
      pie:'cumulative',
      donut:'cumulative',
      radialBar:'cumulative',
      polarArea:'cumulative',
    };

    this.series = [];
    this.labels = [];
    this.colors = [];
    this.type = undefined; // keep this undefined to force manual rendering
    this.spinnerDelay = 150;
    this.stacked = false;
    this.height = '100%';
    this.width = '100%';
    this.xAxisType = 'category';
    this.xAxisRange = undefined;
    this.yAxisMax = undefined;
    this.labelFontSize = '14px';  // only pixels allowed
    this.labelHide = false;
    this.labelPosition = 'right';
    this.labelWidth = 0;
    this.dataLabelsEnabled = false;
    this.dataLabelsFontSize = '14px'; // only pixels allowed
    this.legendFontSize = '14px'; // only pixels allowed
    this.legendPosition = 'bottom';
    this.legendHide = false;
    this.animationsEnabled = false;
    this.strokeCurve =  'smooth';
    this.strokeWidth = 2;
    this.strokeFillType = 'gradient';
    this.fillType = 'gradient';   
    this.fillGradientShade = App.config.isDark ? 'dark' : 'light';
    this.gradientShade = App.config.isDark ? 'dark' : 'light';
    this.gradientType = 'vertical';
    this.gradientInverseColors = false;
    this.pieCustomScale = 1;

    this.handleGradient = false;

    this.options = {
      chart: {
        toolbar: { show: false },
        //selection: { enabled: false },
        height:this.height,
        width:this.width,
        redrawOnParentResize: true,
        redrawOnWindowResize: true,
        stacked:this.stacked,
        animations: {
          enabled: this.animationsEnabled,
          /*
          easing:'easeinout',
          speed: 500,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
          */
        },
        dropShadow: {
          enabled: false,
          opacity: 0.2,
          blur: 5,
          left: 7,
          top: 12
        },
      },
      dataLabels: {
        enabled: this.dataLabelsEnabled,
        style: {
          fontSize: this.dataLabelsFontSize,
          fontWeight: 'normal',
          colors:['#333']
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        }
      },
      grid: {
        show: true,
        borderColor: 'var(--sl-color-neutral-300)',
        strokeDashArray: 0,
        position: 'back',
        xaxis: {
          lines: {
            show: false
          }
        },   
        yaxis: {
          lines: {
            show: true
          }
        },  
        row: {
          colors: undefined,
          opacity: 0.5
        },  
        column: {
          colors: undefined,
          opacity: 0.5
        },  
        padding: {
          top: 10,
          right: 10,
          bottom: 10,
          left: 10
        },  
      },
      plotOptions: {
        pie: {
          //expandOnClick: false, // Désactive l'expansion des sections au clic
          expandOnClick: true,
          customScale: this.pieCustomScale,
          dataLabels: {
            offset: 0, // Réduit l'espace autour des étiquettes de données
            minAngleToShowLabel: 10
          },
          donut: {
            size: '40%',
            labels: {
              // when hovering a slice
              show: false,
              name: {
                show: true,
                fontSize: '50px',
              },
              value:{
                show: true,
                fontSize: '50px',
                color: 'var(--sl-color-neutral-700)',
                offsetY: -10,
                formatter: function(val) {
                  return val;
                }
              },
              total: {
                show: false,
                showAlways: false,
                label: 'Total',
              }
            },
            
          }
        },
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: '30%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            }
          },
          barLabels: {
            enabled: true,
            useSeriesColors: false,
            offsetX: -8,
            fontSize: '14px',
            /*
            formatter: function(seriesName, opts) {
              return seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex]
            },
            */
          },
        }
      },
      tooltip: {
        //enabled: false
        shared: true,
        intersect:false,
      },
      stroke: {
        curve: this.strokeCurve,
        width: this.strokeWidth,
        colors: this.strokeColors,
        fill: {
          type: 'solid', //this.strokeFillType,
          opacity: 1,
          gradient: {
            shade: this.gradientShade,
            type: this.gradientType,
            shadeIntensity: 0.6,
            inverseColors: this.gradientInverseColors,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
            gradientToColors: App.config.isDark ? '#222222' : '#ffffff',
            //colorStops: [],
          }
        }
      },
      fill: {
        type: 'solid', //'this.fillType,'
        opacity: this.fillType === 'solid' ? 1 : 1,
        gradient: {
          shade: this.gradientShade,
          type: this.gradientType,
          //inverseColors: this.gradientInverseColors,
          //shadeIntensity: 0.1,
          //opacityFrom: 1,
          //opacityTo:this.fillType === 'gradient' ? 1 : 1,
          opacityTo: 1,
          stops: this.type === 'bar' ? [30, 95, 100] : [90, 95, 100],
          gradientToColors: App.config.isDark ? '#FFFFFF' : '#000000',
          //colorStops: [],
        },
      },
      xaxis:{
        tickAmount: 'dataPoints',
        //type:this.xAxisType,
        //range:this.xAxisRange,
        //type: 'datetime',
        //tickAmount: 12,
        //min: new Date('01/01/2014').getTime(),
        //max: new Date('01/20/2014').getTime(),
        labels: {
          rotate: -50,
          rotateAlways: true,
          offsetY: 10,
          style: {
            fontSize: this.labelFontSize,
            colors: 'var(--sl-color-neutral-700)',
          },
        },
        axisBorder: {
          show: false,
          color: 'var(--sl-color-neutral-300)',
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
            show: false,
            borderType: 'solid',
            color: 'var(--sl-color-neutral-300)',
            width: 6,
            offsetX: 0,
            offsetY: 0
        },
      },
      yaxis:{
        labels: {
          style: { 
            fontSize: this.labelFontSize,
            colors: 'var(--sl-color-neutral-700)',
          },
        },
        axisBorder: {
          show: false,
          color: 'var(--sl-color-neutral-300)',
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
            show: false,
            borderType: 'solid',
            color: 'var(--sl-color-neutral-300)',
            width: 6,
            offsetX: 0,
            offsetY: 0
        },
      },
      legend:{
        fontSize: this.legendFontSize,
        showForZeroSeries: true,
        floating: false,
        position: this.legendPosition || 'bottom',
        show: this.legendHide === true ? false : true,
        labels: {
          colors: 'var(--sl-color-neutral-700)',
          useSeriesColors: false
        },
        onItemHover: {
          //highlightDataSeries: false
        },
      },
      theme: {
        //mode: App.config.isDark ? 'dark' : 'light',  
      }
    };

    this.originalOptions = deepCloneWithFunctions(this.options);

    // most important is there
    this.options.colors = this.colors;
    this.options.series = this.series;
    this.options.labels = this.labels || [];
    this.options.xaxis.categories = this.labels;

    this.renderChart = this.renderChart.bind(this);
    this.updateOptions = this.updateOptions.bind(this);
    this.updateSeries = this.updateSeries.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.lprefix = this.type ? `#${this.id}(${this.type}):` : `${this.id}:` || '';
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }

  _onThemeChange(e) {
    super._onThemeChange(e);
    this._log.debug(`${this.lprefix} onThemeChange`, e.detail);
    this.fillGradientShade = e.detail.isDark ? 'dark' : 'light';
  }

  async firstUpdated() {
    //this._log.debug(`${this.lprefix} firstUpdated`);

    // fix legend markers (apex bug)
    const style = document.createElement('style');
    style.innerHTML = `  
      .apexcharts-legend-marker svg path {
        transform:translate(45%, 50%) !important;
      }

      .local-chart-container {
        display:flex;
        justify-content:space-around;
      }

      apex-chart {
          display:block;
          flex:1;
        }
    `;
    this.appendChild(style);

    this.container = document.createElement('div');
    this.container.className = 'local-chart-container';
    this.container.innerHTML = '';
    this.appendChild(this.container);

    try {
      await Loaders.loadApexCharts();
      this.apexLoaded = true;
      this._log.debug(`${this.lprefix} ApexCharts library loaded.`);

      this.firstUpdatedTimeout = setTimeout(() => {
        this.firstTime = false;
        if (this.type) {
          this._log.debug(`${this.lprefix} firstUpdatedTimeout: trigger render chart`);
          this.renderChart();
        } else {
          this._log.warn(`${this.lprefix} manual chart rendering required`);
        }

      },300);
    } catch (error) {
      this._log.error('Failed to load ApexCharts library', error);
    }
  }

  async shouldUpdate() {
    if (!this.apexLoaded) {
      this._log.debug(`${this.lprefix} shoudUpdate: apexLoaded=${this.apexLoaded}, series.length=${this.series?.length}`);
      return false;
    }
    return true;
  }

  async updated(changedProperties) {
    //this._log.debug(`${this.lprefix} updated: firstTime=${this.firstTime}`, changedProperties);
    

    for (const [key, oldValue] of changedProperties) {
      if (oldValue === undefined) {
        //this._log.debug(`${this.lprefix} updated: "${key}" has been set to "${this[key]}"`);
      } else if (this[key] !== oldValue) {
        let val = this[key];
        let old = oldValue;
        if (typeof this[key] === 'object') val = JSON.stringify(this[key]);
        if (typeof oldValue === 'object') old = JSON.stringify(oldValue);
        this._log.debug(`${this.lprefix} updated: "${key}" was "${old}" is now "${val}" (${typeof this[key]})`);
        this._log.debug(`${this.lprefix} updated: "${key}" was "${oldValue}" is now "${this[key]}" (${typeof this[key]})`);
      }
    }

    // chart
    if (changedProperties.has('type'))                {
      if (!this.seriesFormatByType[this.type]) {
        throw new Error(`ApexChart: invalid chart type "${this.type}"`);
      }
      this.options.chart.type = this.type;
    }

    if (changedProperties.has('height'))              this.options.chart.height = this.height;
    if (changedProperties.has('width'))               this.options.chart.width = this.width;
    if (changedProperties.has('stacked'))             this.options.chart.stacked = this.stacked;

    // chart animation
    if (changedProperties.has('animationsEnabled'))   this.options.chart.animations.enabled = this.animationsEnabled;

    // stroke
    if (changedProperties.has('strokeCurve'))         this.options.stroke.curve = this.strokeCurve;
    if (changedProperties.has('strokeWidth'))         this.options.stroke.width = this.strokeWidth;

    if (this.handleGradient) {
      if (changedProperties.has('strokeFillType'))      this.options.stroke.fill.type = this.strokeFillType;
      if (changedProperties.has('strokeColors'))        this.options.stroke.colors = this.strokeColors;

      // fill
      if (changedProperties.has('fillType'))            this.options.fill.type = this.fillType;
      if (changedProperties.has('fillGradientShade'))   this.options.fill.gradient.shade = this.fillGradientShade;
      if (changedProperties.has('fillGradientType'))    this.options.fill.gradient.type = this.fillGradientType;

      // stroke & fill gradients
      if (changedProperties.has('gradientShade')) {
        this.options.fill.gradient.shade = this.gradientShade;
        this.options.stroke.fill.gradient.shade = this.gradientShade;
      }

      if (changedProperties.has('gradientType')) {
        this.options.fill.gradient.type = this.gradientType;
        this.options.stroke.fill.gradient.type = this.gradientType;
      }

      if (changedProperties.has('gradientInverseColors')) {
        this.options.fill.gradient.inverseColors = this.gradientInverseColors;
        this.options.stroke.fill.gradient.inverseColors = this.gradientInverseColors;
      }
    }

    // axis
    if (changedProperties.has('labelFontSize'))       this.options.yaxis.labels.style.fontSize = this.labelFontSize;
    if (changedProperties.has('legendFontSize'))      this.options.legend.fontSize = this.legendFontSize;
    if (changedProperties.has('legendPosition'))      this.options.legend.position = this.legendPosition;

    // labels
    if (changedProperties.has('dataLabelsEnabled'))   this.options.dataLabels.enabled = this.dataLabelsEnabled;

    // pie
    if (changedProperties.has('pieCustomScale'))      this.options.plotOptions.pie.customScale = this.pieCustomScale;

    // data
    if (changedProperties.has('colors')) {
      this.options.colors = this.colors;
      this.originalColors = this.colors;
    }

    if (changedProperties.has('labels')) {
      this.options.xaxis.categories = this.labels;
      this.options.labels = this.labels;
      this.originalLabels = this.labels;
    }
    
    if (changedProperties.has('series')) {
      this.options.series = this.series;
      this.originalSeries = this.series;
      if (this.series?.length) {
        this._log.debug(`${this.lprefix} series changed, trigger formatOptionsSeries`, this.series);
        //this.shouldResetChart = changedProperties.get('series')?.length > 1;
        this.formatOptionsSeries();
      }
    }

    if (this.handleGradient) {  
      if (this.type === 'bar') {
        this.options.fill.gradient.stops = [50, 98, 100];
        this.options.fill.gradient.gradientToColors = Array(this.series?.length || 1).fill('#111111');
      } else if (this.type === 'donut') {
        this.options.fill.gradient.stops = [90, 100];
        this.options.fill.gradient.opacityTo = 1;
        this.options.stroke.width = 0;
        this.options.fill.gradient.gradientToColors = Array(this.series?.length || 1).fill('#333333aa');
      }
    }
    
    if (!this.firstTime && this.chart) {
      this._log.debug(`${this.lprefix} updated: trigger updateOptions`);
      await this.updateOptions();
    }
  }

  async renderChart(options) {
    if (options) {
      this.options = options;
    }
    
    /*
    if (!this.series?.length) {
      this._log.warn(`${this.lprefix} renderChart: no series data, skipping`);
      return;
    }
    */

    this._log.debug(`${this.lprefix} renderChart: width=${this.options.chart.width}, height=${this.options.chart.height}`);
    this.chart = new ApexCharts(this.container, this.options);
    await this.chart.render();
  }

  detectSeriesFormat(data) {
    let format = 'timeseries';
    for (let i = 0; i < data.length; i++) {
      if (typeof data[i] === 'number') {
        this._log.debug(`${this.lprefix} detectSeriesFormat: detected series format: cumulative`);
        format = 'cumulative';
        return format;
      }
    }
    return format;
  }

  transformTimeSeriesData(series) {
    const newSeries = [];
    for (let i = 0; i < series.length; i++) {
      const data = series[i].data;
      let cumulative = 0;
      data.map((d) => cumulative += d );
      newSeries.push(cumulative);
    }
    return newSeries;
  }

  transformTimeSeriesLabels(series) {
    const newLabels = [];
    for (let i = 0; i < series.length; i++) {
      newLabels.push(series[i].name);
    }
    return newLabels;
  }

  /*
  cleanCurrentOptions() {
    delete this.options.xaxis;
    delete this.options.yaxis;
    delete this.options.stroke;
    delete this.options.fill;
    delete this.options.annotations;
    delete this.options.tooltip;
    delete this.options.grid;
    delete this.options.dataLabels;
    delete this.options.legend;
    delete this.options.plotOptions;
    delete this.options.chart.toolbar;
    console.log('cleanCurrentOptions: original options toolbar', this.originalOptions.chart.toolbar);
    console.log('cleanCurrentOptions: options toolbar', this.options.chart.toolbar);
  }
  */

  formatOptionsSeries() {
    if (!this.type) return;
    const requiredFormat = this.seriesFormatByType[this.type];
    
    const currentSeriesFormat = this.detectSeriesFormat(this.options.series);
    if (currentSeriesFormat === requiredFormat) {
      this._log.debug(`${this.lprefix} formatOptionsSeries: series format is already ${currentSeriesFormat}`);
      this.shouldResetChart = false;
      return;
    }
    this.shouldResetChart = true;

    if (requiredFormat === 'cumulative') {
      this._log.debug(`${this.lprefix} formatOptionsSeries: reformatting series for chart type ${this.type}, current ${currentSeriesFormat}, expected ${requiredFormat}`);
      const series = this.series;
      //this.cleanCurrentOptions();
      this.options.series = this.transformTimeSeriesData(series);
      this.options.labels = this.transformTimeSeriesLabels(series);
    } else {
      this._log.debug(`${this.lprefix} formatOptionsSeries: restoring original options and series`);
      this.options = deepCloneWithFunctions(this.originalOptions);
      this.options.chart.type = this.type;
      this.options.colors = this.originalColors;
      this.options.series = this.originalSeries;
      this.options.xaxis.categories = this.originalLabels;
    }

    
  }

  async updateSeries(series) {
    if (!this.chart) return;
    this._log.debug(`${this.lprefix} updateSeries`, this.chart);
    this.options.series = series;
    this.formatOptionsSeries();
    await this.chart.updateSeries(series);
  }

  async updateOptions(options) {
    if (!this.chart) return;
    if (options) this.options = options;

    if (this.lprefix.match(/distribution/)) {
      this._log.info(`${this.lprefix} updateOptions,
        fillType=${this.fillType},
        fill=${JSON.stringify(this.options.fill, null, 4)},
        stroke.fill=${JSON.stringify(this.options.stroke.fill, null, 4)}
      `);
    }

    this.formatOptionsSeries();
    if (this.shouldResetChart) {
      this._log.debug(`${this.lprefix} updateOptions, recreating shart with new options`);
      this.chart.destroy();
      this.chart = null;
      await this.renderChart();
      return;
    }

    this._log.debug(`${this.lprefix} updateOptions`, this.options);
    await this.chart.updateOptions(this.options);
  }

  createRenderRoot() {
    return this;
  }
}

customElements.define('apex-chart', ApexChart);
